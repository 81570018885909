import { Navigate, useLocation } from 'react-router-dom';
import { useGetCurrentUser } from '../../app/hooks/useUser';

const RedirectAuthenticated = ({ children }: { children: React.ReactNode }) => {
  const user = useGetCurrentUser();
  const location = useLocation();

  if (user) {
    const callbackUrl = new URLSearchParams(location.search).get(
      'callbackUrl',
    ) as string;
    return <Navigate to={callbackUrl || '/dashboard'} />;
  }

  return <>{children}</>;
};

export default RedirectAuthenticated;
