import { useEffect } from 'react';
import { useGetCurrentUser } from './app/hooks/useUser';
import Router from './router';
import sharedSocket from './utils/socket';

function App() {
  const user = useGetCurrentUser();
  useEffect(() => {
    sharedSocket.emit('addUser', user?.id);
  }, [user?.id]);
  return (
    <div className="w-screen h-screen overflow-hidden">
      <Router />
    </div>
  );
}

export default App;
