// SharedSocket.js
import { io } from 'socket.io-client';

const baseURL =
  process.env.REACT_APP_ENV !== 'development'
    ? 'https://avbids.com'
    : 'http://localhost:5000';

const socket = io(baseURL);


export default socket;
