import axios from 'axios';

const baseURL =
  process.env.REACT_APP_ENV !== 'development' ? '/api' : 'http://localhost:5000/api';

export const refreshToken = async () => {
  try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    const refreshToken = userInfo.tokens.refresh_token;
    const { data } = await axios.post(
      `${baseURL}/auth/refresh`,
      {}, // empty body
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    if (userInfo && userInfo.tokens) {
      config.headers.Authorization = `Bearer ${userInfo.tokens.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default api;
