import { jwtDecode } from 'jwt-decode';
import { FC, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import SuspenseScreen from '../router/suspense-screen';
import { refreshToken } from '../utils/api';
import api from '../utils/api'; // Import your Axios instance

interface RequireAuthProps {
  children: React.ReactNode;
}

const RequireAuth: FC<RequireAuthProps> = ({ children }) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const storedUser = JSON.parse(localStorage.getItem('userInfo') || '{}');
        if (storedUser.tokens) {
          const decodedToken = jwtDecode(storedUser?.tokens?.access_token);
          if (decodedToken.exp! * 1000 < Date.now()) {
            // Access token expired, attempt to refresh tokens
            const data = await refreshToken();
            localStorage.setItem(
              'userInfo',
              JSON.stringify({
                ...storedUser,
                tokens: {
                  access_token: data.tokens.access_token,
                  refresh_token: data.tokens.refresh_token,
                },
              }),
            );

            // Set the refreshed access token in the Axios instance headers
            api.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${data.tokens.access_token}`;

            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(true);
          }
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        localStorage.removeItem('userInfo');
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  // Refresh token on every API call
  useEffect(() => {
    const interceptResponse = async (error: any) => {
      if (error.response && error.response.status === 401) {
        try {
          // Access token expired, attempt to refresh tokens
          const data = await refreshToken();
          localStorage.setItem(
            'userInfo',
            JSON.stringify({
              ...JSON.parse(localStorage.getItem('userInfo') || '{}'),
              tokens: {
                access_token: data.tokens.access_token,
                refresh_token: data.tokens.refresh_token,
              },
            }),
          );

          // Set the refreshed access token in the Axios instance headers
          api.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${data.tokens.access_token}`;

          setIsAuthenticated(true);

          // Reattempt the original request
          return api.request(error.config);
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          // Redirect to sign-in page if token refresh fails
          return Promise.reject(refreshError);
        }
      } else if (error.response && error.response.status === 403) {
        // If refresh token returns 403, clear localStorage and redirect to sign-in
        localStorage.removeItem('userInfo');
        return <Navigate to="/sign-in" replace />;
      }
      return Promise.reject(error);
    };

    const interceptorId = api.interceptors.response.use(
      (response) => response,
      interceptResponse,
    );

    return () => {
      api.interceptors.response.eject(interceptorId);
    };
  }, []);

  if (isLoading) {
    return <SuspenseScreen />;
  }

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate to={`/`} />
  );
};

export default RequireAuth;
